// Desktop mixins

@mixin h1_desktop {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 96px;
  line-height: 92px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

@mixin h2_desktop {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 61px;
  line-height: 60px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

@mixin h3_text {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

@mixin h4_text {
  font-family: 'DIN 2014';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

@mixin paragraph {
  font-family: 'Arial Narrow';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

@mixin paragraph_massive {
  font-family: 'DIN 2014';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

@mixin button_text {
  font-family: 'Akzidenz-Grotesk Pro XBd Cnd';
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
  text-transform: uppercase;
}

@mixin numbers_big {
  font-family: 'Akzidenz-Grotesk Pro Bold Cnd';
  font-weight: 700;
  font-size: 100px;
  line-height: 80px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

// Mobile mixins

@mixin h1_mobile {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

@mixin h2_mobile {
  font-family: 'DIN 2014';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

@mixin h4_mobile {
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}
