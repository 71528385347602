@font-face {
  font-family: 'Arial Narrow';
  src: url('../assets/fonts/ArialNarrow.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow.woff') format('woff'),
    url('../assets/fonts/ArialNarrow.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Bold Cnd';
  src: url('../assets/fonts/AkzidenzGroteskPro-BoldCn.woff2') format('woff2'),
    url('../assets/fonts/AkzidenzGroteskPro-BoldCn.woff') format('woff'),
    url('../assets/fonts/AkzidenzGroteskPro-BoldCn.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro XBd Cnd';
  src: url('../assets/fonts/AkzidenzGroteskPro-XBdCn.woff2') format('woff2'),
    url('../assets/fonts/AkzidenzGroteskPro-XBdCn.woff') format('woff'),
    url('../assets/fonts/AkzidenzGroteskPro-XBdCn.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('../assets/fonts/ArialNarrow-Bold.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow-Bold.woff') format('woff'),
    url('../assets/fonts/ArialNarrow-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('../assets/fonts/ArialNarrow_1.woff2') format('woff2'),
    url('../assets/fonts/ArialNarrow_1.woff') format('woff'),
    url('../assets/fonts/ArialNarrow_1.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../assets/fonts/DIN2014-Regular.woff2') format('woff2'),
    url('../assets/fonts/DIN2014-Regular.woff') format('woff'),
    url('../assets/fonts/DIN2014-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../assets/fonts/DIN2014-Bold.woff2') format('woff2'),
    url('../assets/fonts/DIN2014-Bold.woff') format('woff'),
    url('../assets/fonts/DIN2014-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';
  src: url('../assets/fonts/DIN2014-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/DIN2014-ExtraBold.woff') format('woff'),
    url('../assets/fonts/DIN2014-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
