@import '../../styles/mixins';
@import '../../styles/colors';

.spinner {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: all 1s linear;
  transition: display 2s linear;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  &_white {
    border: 8px solid $white_main;
    border-bottom-color: $red_main;
  }
  &_red {
    border: 8px solid $red_main;
    border-bottom-color: $white_main;
  }
}
