@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/fonts_icon/icomoon.eot?vawkfz');
  src: url('../assets/fonts/fonts_icon/icomoon.eot?vawkfz#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/fonts_icon/icomoon.ttf?vawkfz') format('truetype'),
    url('../assets/fonts/fonts_icon/icomoon.woff?vawkfz') format('woff'),
    url('../assets/fonts/fonts_icon/icomoon.svg?vawkfz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telegram:before {
  content: '\e900';
  font-size: 12px;
}
.icon-tiktok:before {
  content: '\e901';
  font-size: 13px;
}
.icon-vk:before {
  content: '\e902';
  font-size: 10px;
}

// на иконку аккаунта нельзя установить цвет - нужно каждый раз подгружать иконку нужного цвета
.icon-account {
  &_white {
    &::before {
      content: url('../assets/icons/account/account_light.svg');
    }
  }

  &_black {
    &::before {
      content: url('../assets/icons/account/account.svg');
    }
  }
}
