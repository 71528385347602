@import '../../styles/colors';
@import '../../styles/mixins';

.social-media {
  display: flex;
  z-index: 5;
  gap: 16px;
  &_promo {
    justify-content: left;
    align-items: center;
  }
  &_b2b {
    margin-top: 42px;
  }
  &__link {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    position: relative;
    width: 34px;
    outline: 0;
    height: 34px;
    cursor: pointer;
    text-decoration: none;
    &::before {
      content: '';
      background: $grey_secondary;
      border-radius: 50px;
      bottom: 0;
      color: $white_main;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
    &_white {
      span {
        &::before {
          color: $white_main;
        }
      }
    &_black {
      span {
        &::before {
          color: $main_black
        }
      }
    }
    }
    &_grey-secondary {
      span {
        &::before {
          color: $grey_secondary;
        }
      }
    }
    &:hover,
    &:focus-visible,
    &:focus {
      .icon-account {
        &_white {
          &::before {
            content: url('../../assets/icons/account/account_red.svg');
          }
        }
        &_black {
          &::before {
            content: url('../../assets/icons/account/account_red.svg');
          }
        }
      }
      span {
        &::before {
          color: $red_main;
        }
      }

      &::before {
        opacity: 0.1;
      }
    }
  }
}
