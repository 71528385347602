@import '../../styles//mixins';
@import '../../styles//colors';

.footer {
  background-color: $main_black;
  min-height: 223px;
  padding: 24px 0px 46px 0px;

  &__logo {
    width: 128px;
    margin-top: 11px;
  }
  &__appeal {
    color: $white_main;
    text-align: center;
    font-family: 'DIN 2014';
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-transform: uppercase;
    &__divider {
      display: block;
    }
    &__divider1 {
      display: none;
    }
    &_link-red {
      color: $red_main;
      scroll-behavior: smooth;
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 1px solid $red_main;
      &:hover {
        border: none;
        color: $dark_red;
        transition: color 0.3s, border 0.3s;
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_ufc {
      margin-top: 48px;
      align-items: flex-start;
    }
    &_social-media {
      margin-top: 40px;
    }
  }
  &__contacts {
    display: flex;
    justify-content: space-between;
  }
  &__chapter {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__header {
    color: $light_grey;
  }
  &__list {
    text-decoration: none;
    &-item {
      color: $grey_secondary;
      list-style: none;
      text-transform: none;
      margin-top: 8px;
    }
  }
  &__link {
    color: $grey_secondary;
    text-decoration: none;
    text-transform: none;
    &:hover {
      color: $light_grey;
    }
  }
  &__sole-trader {
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    align-items: start;
    &_320px {
      display: none;
    }
  }
}
