@media (max-width: 1220px) {
  .back-btn {
    &__wrapper {
      width: 688px;
      margin: auto;
    }
  }
  .button {
    &_active {
      width: 688px;
    }
  }
}
@media (max-width: 1160px) {
  .b2b {
    &__forms-wrapper {
      flex-direction: column;
      align-items: center;
    }
    &__form-promocode {
      margin-right: 0;
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 768px) {
  .back-btn {
    &__wrapper {
      width: 540px;
      margin: auto;
    }
  }
  .button {
    &_b2b {
      margin-top: 20px;
      margin-right: 0px;
    }
    &_active {
      width: 560px;
    }
  }
  .b2b {
    &__wrapper {
      padding: 80px 0px 60px 0px;
    }
    &__header {
      margin-top: 70px;
    }
    &__dynamic {
      padding: 60px 0px 88px 0px;
    }
    &__buttons {
      flex-direction: column;
      width: auto;
    }
  }
}

@media (max-width: 576px) {
  .back-btn {
    &__wrapper {
      width: 288px;
      margin: auto;
    }
  }
  .button {
    &_promocode-check {
      width: 256px;
    }
    &_b2b {
      width: 288px;
    }
    &_active {
      width: 288px;
      margin-bottom: 8px;
    }
  }
  .b2b {
    &__form-promocode {
      &:last-child {
        margin-top: 8px;
      }
    }
    &__wrapper {
      padding: 40px 0px 40px 0px;
    }
    &__header {
      @include h3_text;
      margin-top: 40px;
    }
    &__dynamic {
      padding: 40px 0px 48px 0px;
    }
    &__form-promocode {
      width: 288px;
      padding: 24px 16px;
    }
    &__ogon {
      height: 103px;
    }
  }

  .modal {
    &__title {
      &_b2b {
        @include h4_text;
        text-align: center;
      }
    }
    &__input {
      &_b2b-promocode {
        width: 256px;
      }
    }
  }
}
