@import '../../styles//mixins';
@import '../../styles//colors';

@media (max-width: 992px) {
  .footer {
    padding: 24px 0px 47px 0px;
    &__wrapper {
      &_ufc {
        margin-top: 40px;
      }
    }
    &__chapter {
      width: 140px;
      margin-right: 30px;
    }
    &__link {
      color: $white_main;
      &:hover {
        color: $white_main;
      }
      &:active {
        color: $light_grey;
      }
    }
    &__sole-trader {
      margin-top: 66px;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &__chapter {
      margin-right: 0;
    }
  }
}

@media (max-width: 700px) {
  .footer {

    &__appeal {
      font-size: 21px;
      line-height: 32px;

      &__divider1 {
        display: block;
      }

      &__divider {
        display: none;
      }

    }
    &__wrapper {
      &_ufc {
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
      }
      &_social-media {
        flex-direction: column;
      }
    }
    &__contacts {
      flex-direction: column;
      margin-top: 0px;
      text-align: center;
    }
    &__chapter {
      width: auto;
    }
    &__header {
      margin-top: 40px;
    }
    &__list {
      &-item {
        margin-top: 16px;
      }
    }
    &__info {
      order: 3;
      margin-top: 40px;
      text-align: center;
      &_sole-trader {
        margin-top: 0;
      }
    }
    &__sole-trader {
      &_768px {
        display: none;
      }
      &_320px {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 2;
      }
    }
  }
}
