.button {
  display: flex;
  position: relative;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background: $red_main;
  margin: 0 auto;
  width: 248px;
  height: 50px;
  border: none;
  @include button_text;
  color: $white_main;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 0;
    bottom: 0;
    background: $red_main;
    transition: width 1s ease-in-out 0s;
  }
  &:hover {
    background: $dark_red;
    transition: color 0.3s, background-color 0.3s;
    &::after {
      content: '';
      width: 100%;
      right: 0;
    }
  }
  &_active-type {
    background: $dark_red;
    transition: color 0.3s, background-color 0.3s;
  }
  &_promo {
    margin-top: 80px;
  }
  &_news {
    margin-top: 25.5em;
    width: 300px;
    height: 68px;
  }
  &_tariffs {
    margin-top: auto;
    &_fighter {
      width: 194px;
    }
    &_month {
      margin-top: auto;
      height: 50px;
    }
  }
  &_modal {
    margin-top: 40px;
    width: 400px;
    &-opacity {
      opacity: 0.5;
      cursor: auto;
      &:hover {
        background: $red_main;
        box-shadow: none;
      }
    }
    &_reg {
      margin-top: 62px;
    }
  }
  &_white {
    background: #ffffff;
    color: $main_black;
    margin: none;
    transition: background-color 1s ease-in-out;
    &:hover {
      background: $light_grey;
      box-shadow: none;
    }
    &::after {
      background: $main_black;
      transition: width 1s ease-in-out 0s;
    }
    &-card {
      background: #ffffff;
      color: $main_black;
      border: 2px solid rgba($grey_secondary, 0.5);
      margin: none;
      &:hover {
        background: #ffffff;
        &::after,
        &::before {
          content: none;
        }
      }
    }
    &-promotion {
      background: $white_main;
      border: 1px solid #D5D6D9;
      text-align: center;
      width: 280px;
      padding: 14px 0;

      &_big {
        width: 322px;
      }
    }
    &-modal {
      width: 100%;
      margin-top: 16px;
      border: 1px solid rgba($grey_secondary, 0.5);
    }
  }
  &_thanks {
    margin-top: 40px;
    width: 400px;
  }
  &_promocode-check {
    width: 320px;
    margin-top: 40px;
  }
  &_b2b {
    width: 320px;
    &-main {
      text-align: center;
      width: 320px;
      margin-top: 40px;
    }
    &-red {
    }
    &-white {
      background: $white_main;
      color: $main_black;
      &::after {
        background: $main_black;
        transition: width 1s ease-in-out 0s;
      }
      &:hover {
        background: $light_grey;
      }
    }
  }
  &_account {
    width: 320px;
    margin-top: 40px;
    &-card {
      width: 320px;
      margin-top: 40px;
    }
  }
  &_my-promocodes {
    width: 320px;
  }
  &_account-history {
    width: 320px;
    margin-top: 40px;
  }
  &_active {
    width: 100%;
    margin-bottom: 20px;
  }
  &_mt-40 {
    margin-top: 40px;
  }
}

.back-btn {
  @include paragraph;
  color: $white_main;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $main_black;
  border: none;
  text-transform: none;

  &__img {
    margin-right: 8px;
  }
  &__text {
    &:hover {
      text-decoration: underline;
    }
  }
  &__wrapper {
    width: 1200px;
    margin: auto;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

@media (max-width: 1240px) {
  .button {
    &_tariffs {
      &_fighter {
        width: 248px;
      }
    }
  }
}

@media (max-width: 1220px) {
  .button {
    &_my-promocodes {
      width: 608px;
      margin-top: 8px;
    }
  }
  .buttons {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .button {
    &:hover {
      background: $red_main;
    }
    &_white {
      &:hover {
        background: $white_main;
      }
      &-card {
        &:hover {
          background: #ffffff;
          &::after,
          &::before {
            content: none;
          }
        }
      }
    }
  }
  .back-btn {
    &__text {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .button {
    &_b2b {
      &-main {
        width: 288px;
      }
    }
  }
}

@media (max-width: 768px) {
  .button {
    &_my-promocodes {
      width: 480px;
    }

    &_tariffs {
      width: 248px;
    }
  }
}

@media (max-width: 578px) {
  .button {
    &_account {
      width: 288px;
      &-card {
        width: 256px;
      }
    }
    &_my-promocodes {
      width: 256px;
    }
    &_account-history {
      width: 288px;
    }
    &_modal {
      &_reg {
        margin-top: 40px;
      }
    }
  }
}
