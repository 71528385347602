@media (max-width: 992px) {
  .button {
    &:active {
      background: $dark_red;
      box-shadow: inset 0px -2px 0px $red_main;
    }
    &_promo {
      margin-top: 60px;
    }
    &_news {
      margin-top: 82px;
    }
    &_b2b {
      &-white {
        &:hover {
          background: $white_main;
        }
      }
    }
  }
  .button_modal_white:hover {
    background: #ababab;
  }

  .modal {
    &__dialog {
      margin-top: 32px;
    }
    &__content {
      max-height: 754px;
    }
    &__input {
      margin-top: 56px;

      &#email {
        margin-top: 40px;
      }
      &_b2b-email#email {
        margin-top: 0;
      }
    }
    &__checkboxes {
      &:last-child {
        margin-top: 16px;
      }
    }
    &__checkbox {
      // transform: scale(1.4);
      &:checked + .modal__checkbox__label::before {
        background: url('../../../assets/icons/check_mark_big.svg') center
          $red_main no-repeat;
      }
      &__label {
        &::before {
          // transform: scale(1.2);
          width: 20px;
          height: 20px;
        }
        &:hover {
          &::before {
            border: 1px solid $light_grey;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .button {
    &_promo {
      margin-top: 60px;
    }
  }
  .payment {
    &__item {
      &__descr {
        width: 190px;
      }
    }
  }
  .active {
    padding-bottom: 150px;
  }
}

@media (max-width: 576px) {
  .button {
    &_promo {
      margin-top: 40px;
    }
    &_news {
      margin-top: 20px;
    }
    &_modal,
    &_thanks {
      width: 288px;
    }
  }

  .active {
    padding-bottom: 100px;
    &__title {
      @include h3_text;
    }
    &__video {
      width: 288px;
      height: 380px;
    }
  }

  .modal {
    &__dialog {
      width: 320px;
      margin: 0px auto;
      margin-top: 16px;
    }
    &__content {
      padding: 0px 16px 40px 16px;
    }
    &__close {
      top: 20px;
      right: 20px;
    }
    &__title {
      margin-top: 56px;
      &_main {
        margin-top: 24px;
      }
    }
    &__icon {
      margin-top: 40px;
    }
    &__input {
      width: 280px;
      margin-top: 32px;
      &_b2b-email#email {
        margin-top: 40px;
      }
    }
    &__checkboxes {
      margin-top: 40px;
      &:last-child {
        margin-top: 24px;
      }
    }
    &__tabs {
      margin-top: 64px;
    }
    &__tab {
      @include paragraph_massive;
      width: 136px;
      height: 50px;
    }
    &__wrong {
      margin-right: 10px;
    }
  }
  .box {
    width: 44px;
    height: 60px;
  }
  .fieldset {
    margin-left: 0;
    margin-right: 0;
  }
  .toolkit-modal {
    width: 280px;
    &__wrapper {
      top: 52px;
    }
  }
}
