@import "../_colors.scss";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  &__dialog {
    width: 480px;
    margin: 64px auto;
  }

  &__promotion {
    color: $red_main;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'DIN 2014';
    margin-top: 24px;
  }

  &__avto {
    display: flex;
    justify-content: space-between;
  }
  &__question {
    display: block;
    margin-top: 40px;
    position: relative;
  }
  &__wrong {
    font-family: 'DIN 2014';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    text-align: right;
    color: $red_main;
    margin-right: 27px;
    margin-top: 8px;
    &_tar {
      margin-right: 0;
    }
  }
  &__buy-promo-link {
    color: $main_black;
    margin-top: 24px;
    cursor: pointer;
    text-align: center;
  }
  &__email {
    color: $red_main;
    cursor: pointer;
  }
  &__link {
    color: $red_main;
    text-decoration: none;
    cursor: pointer;
  }
  &__subheader {
    @include h4_text;
    margin-top: 40px;
    text-align: center;
  }
  &__registration {
    @include h4_text;
    margin-top: 24px;
    text-align: center;
    a {
      color: $red_main;
    }
  }
  &__tabs {
    margin-top: 64;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  &__tab {
    @include h4_text;
    color: $main_black;
    width: 196px;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    background-color: $white_main;
    cursor: pointer;
    border: none;
    &:hover {
      color: $red_main;
      transition: all 0.5s;
    }
    &_active {
      color: $red_main;
      border-bottom: 2px solid;
    }
  }
  &__content {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 40px 64px 40px;
    background-color: $white_main;
    border: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 698px;
  }
  &__close {
    z-index: 3;
    position: absolute;
    display: flex;
    width: 34px;

    height: 34px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 25px;
    right: 25px;
    &::before {
      content: '';
      background: $grey_secondary;
      border-radius: 50px;
      bottom: 0;
      color: $white_main;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
    &:hover {
      img {
        transition: all 0.5s;
        transform: rotate(90deg);
      }
      &::before {
        opacity: 0.1;
      }
    }
    img {
      pointer-events: none;
    }
  }
  &__mt-24 {
    margin-top: 24px;
  }
  &__policy {
    @include paragraph_massive;
    font-size: 10px;
    line-height: 16px;
    color: $light_grey;
    text-transform: none;
    text-align: center;
    &-link {
      color: $grey_secondary;
      text-decoration: underline;
    }
  }
  &__title {
    @include h3_text;
    color: $main_black;
    text-align: center;
    &_thanks {
      margin-top: 40px;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $red_main;
    border: 1px solid $red_main;
    border-radius: 200px;
    width: 80px;
    height: 80px;

    img {
      display: block;
      width: 20px;
    }
  }
  &__descr {
    text-align: center;
    @include paragraph_massive;
    color: $main_black;
    text-transform: none;
    margin-top: 24px;
    &_grey {
      color: $grey_secondary;
    }
    &_mt-40 {
      margin-top: 40px;
    }
  }
  &__item {
    position: relative;
  }
  &__input {
    position: relative;
    display: block;
    margin-top: 40px;

    height: 40px;
    outline: none;
    background: $white_main;
    border: none;
    border-bottom: 1px solid $light_grey;
    @include paragraph_massive;
    text-transform: none;

    &_main {
      width: 400px;
    }
    &_b2b-promocode {
      width: 320px;
      &#email {
        margin-top: 0px;
      }
    }
    &-invalid {
      border-bottom: 1px solid $red_main;
    }
    &_b2b-email {
      margin-top: 0px;
    }
    &:focus {
      color: $main_black;
      border-bottom: 1px solid $grey_secondary;
    }
  }
  &__label {
    position: absolute;
    left: 0px;
    top: 9px;
    @include paragraph_massive;
    text-transform: none;
    color: $light_grey;
    pointer-events: none;
    transition: all 0.3s;
    &-active {
      // max-width: 133%;
      transition: all 0.3s;
      transform: translateY(-18px) translateX(-12%) scale(0.75);
      pointer-events: auto;
    }
  }
  &__checkboxes {
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: left;
    &_mt-24 {
      margin-top: 24px;
    }
    &:last-child {
      margin-top: 0px;
      margin-bottom: 0;
    }
    &__avto {
      margin-top: 40px;
      @include paragraph;
    }
    &_flex {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    &__tooltip {
      position: absolute;
      z-index: 5;
      right: -11px;
      top: 36px;
      &-trigger {
        cursor: pointer;
      }
    }
    &_mt-40 {
      margin-top: 40px;
    }
  }
  &__reg {
    &__title {
      margin-top: 72px;
    }
  }
  &__checkbox {
    // width: 16px;
    // height: 16px;
    // display: none;
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked ~ .modal__checkbox__label::before {
      border-color: $red_main;
      opacity: 1;
      background: url('../../assets/icons/check_mark_small.svg') center
        $red_main no-repeat;
    }
    &_opacity {
      &:checked ~ .modal__checkbox__label::before {
        border-color: $red_main;
        opacity: 0.5;
        background: url('../../assets/icons/check_mark_small.svg') center
          $red_main no-repeat;
      }
    }

    &__label {
      @include paragraph;
      color: $main_black;
      text-transform: none;
      user-select: none;
      &_error {
        color: $red_main;
        font-weight: bold;
      }

      &::before {
        content: ' ';
        color: $red_main;
        display: inline-block;
        margin-right: 8px;
        text-align: center;
        text-indent: 0px;
        width: 16px;
        height: 16px;
        background: $white_main;
        border: 1px solid $light_grey;
        border-radius: 2px;
        vertical-align: sub;
      }
      &:hover {
        &::before {
          border: 1px solid $grey_secondary;
        }
      }
    }
    &__link {
      display: inline-block;
      color: $red_main;
      font-family: 'Arial Narrow';
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0%;
      // padding-bottom: 0.2px;
      // border-bottom: 1px solid $red_main;
      &:hover {
        text-decoration: none;
        color: $dark_red;
      }
    }
  }
  &__countdown {
    color: $grey_secondary;
    text-align: center;
    margin-top: 24px;
  }
}

.show {
  display: block;
}
.hide {
  display: none;
}
input.error {
  border-bottom: 1px solid $red_main;
}
label.error {
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  text-transform: none;
  margin-top: 8px;
  font-family: 'DIN 2014';
  font-weight: 400;
  font-size: 12px;
  color: $red_main;
}
.photo {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative; /* Относительное позиционирование */
}
.photo:hover::after {
  content: url('../../assets/icons/photo_3.svg');
  position: absolute;
  right: -54px;
  top: 35px;
  z-index: 10000;
}

:root {
  /* colors palette */
  --placeholder-color: hsl(240, 54%, 87%);
  --bg-color: hsl(240, 54%, 97%);
  --focus-color: hsla(240, 54%, 61%, 0.6);
  --shadow-color: hsla(240, 54%, 61%, 0.2);
  --text-color: hsl(0, 0%, 20%);
  --text-color-inversed: hsl(0, 0%, 95%);
  --success-color: hsl(145, 63%, 42%);
  --success-color-desaturated: hsl(145, 0%, 42%);
  --failure-color: #cd2a34;

  /* border-radius */
  --border-radius: 6px;

  /* z-index */
  --z-index-xs: 1;
  --z-index-sm: 10;
  --z-index-md: 100;

  /* easing */
  --easing: cubic-bezier(0.25, 0.01, 0.25, 1);

  /* transition durations */
  --transition-duration-step-1: 450ms;
  --transition-duration-step-2: 300ms;
  --transition-duration-step-3: 300ms;

  /* transition delays */
  --transition-delay-step-2: calc(var(--transition-duration-step-1));
  --transition-delay-step-3: calc(
    var(--transition-duration-step-1) + var(--transition-duration-step-2)
  );

  /* transition properties */
  --transition-step-1: var(--transition-duration-step-1) var(--easing);
  --transition-step-2: var(--transition-duration-step-2) var(--easing)
    var(--transition-delay-step-2);
  --transition-step-3: var(--transition-duration-step-3) var(--easing)
    var(--transition-delay-step-3);
}

/* General styles */

.main {
  display: grid;
  justify-content: center;
}

.animation-controls {
  margin-top: 55px;
}
.animation-controls__content {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 6px;
}
.btn {
  min-width: 90px;
  padding: 9px 0;
  border: 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 150ms ease-in;
}
.btn:hover,
.btn:focus {
  opacity: 0.7;
}
.btn:active {
  position: relative;
  top: 1px;
}
.btn.success-btn {
  color: #219653;
  background-color: #d3eadd;
}
.btn.failure-btn {
  color: #eb5757;
  background-color: #ffe9e0;
}
.btn.reset-btn {
  color: #6666d1;
  background-color: #f2f2f2;
}
.settings-controls {
  margin-top: 130px;
}
.settings-controls__content {
  display: grid;
  justify-content: center;
  gap: 18px;
}
.settings-controls__input {
  width: 67px;
  height: 33px;
  border: 0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 0.01em;
  color: #828282;
  // background-color: #f2f2f2;
  text-align: center;
}
.settings-controls__label {
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  font-weight: bold;
  color: #828282;
}

@media (min-width: 768px) {
  .settings-controls__content {
    grid-auto-flow: column;
  }
}

/* SMS Code input styles */
/* base styles */

.fieldset {
  display: grid !important;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 8px;
  border-radius: 6px;
  padding: 6px;
  margin: 24px;
  margin-bottom: 0;
}

.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  overflow: hidden;
  will-change: transform;
  width: 60px;
  height: 60px;
  border: 1px solid #595b64;
  border-radius: 8px;
}
.box:focus-within {
  box-shadow: 0 0 6px 1px var(--shadow-color), 0 0 0 2px var(--focus-color);
}

.field {
  position: relative;
  border: 0;
  outline: 0;
  font-family: 'Arial Narrow';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  color: var(--text-color);
  background-color: transparent;
  text-align: center;
  z-index: var(--z-index-md);
}
.field::placeholder {
  color: var(--placeholder-color);
}

/* End SMS Code input styles */
.hidden {
  opacity: 0;
}
.modal__wrong {
  transition: all 2s ease;
}

.toolkit-modal {
  width: 380px;
  padding: 8px 16px;
  background: $main_black;
  border-radius: 8px;
  text-align: center;
  &__text {
    @include paragraph_massive;
    text-transform: none;
    color: $white_main;
  }
  &__anchor {
    // display: inline;
    // transform: rotate(45deg);
    position: absolute;

    right: 14px;
    top: -16px;

    width: 0px;
    // height: 8px;

    border-radius: 3px;
    // background: $main_black;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    border: 10px solid $main_black;
    border-color: transparent transparent $main_black transparent;
  }
  &__wrapper {
    position: absolute;
    z-index: 5;
    right: -12px;
    top: 35px;
  }
}
