$red_main: #cd2a34;
$dark_red: #9b2224;
$white_main: #fbfcfe;
$main_black: #16171b;
$grey_secondary: #595b64;
$light_grey: #ababab;
$blue_links: #0033ff;
$background_color: #fbfcfe;
$yellow: #dbb958;

.col-red-main {
  color: #cd2a34;
}

.col-dark-red {
  color: #cd2a34;
}

.col-white-main {
  color: #fbfcfe;
}

.col-black-main {
  color: #16171b;
}

.col-grey-secondary {
  color: #595b64;
}

.col-grey-light {
  color: #ababab;
}

.col-blue-links {
  color: #0033ff;
}
