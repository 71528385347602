  // Используемые классы - родители с основными стилями
.h1-desktop {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 96px;
  line-height: 92px;
  text-transform: uppercase;
}

.h2-desktop {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 61px;
  line-height: 60px;

  text-transform: uppercase;
}

.h3-text {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-transform: uppercase;
}

.h4-text {
  font-family: 'DIN 2014';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  &_link-red {
    color: $red_main;
    scroll-behavior: smooth;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid $red_main;
    &:hover {
      border: none;
      color: $dark_red;
      transition: color 0.3s, border 0.3s;
    }
  }
}

.paragraph {
  font-family: 'Arial Narrow';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  &_grey-secondary {
    color: $grey_secondary;
    opacity: 0.5;
  }
  &_light-grey {
    color: $light_grey;
  }
  &_light-grey-opacity {
    color: $light_grey;
    opacity: 0.5;
  }
}

.paragraph-massive {
  font-family: 'DIN 2014';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  &_grey-secondary {
    color: $grey_secondary;
  }
}

.button-text {
  font-family: 'Akzidenz-Grotesk Pro XBd Cnd';
  font-weight: 900;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}

.numbers-big {
  font-family: 'Akzidenz-Grotesk Pro Bold Cnd';
  font-weight: 700;
  font-size: 100px;
  line-height: 80px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

// Mobile typography
.h1-mobile {
  font-family: 'Arial Narrow';
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  letter-spacing: -3%;
  text-transform: uppercase;
}

.h2-mobile {
  font-family: 'DIN 2014';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

.h4-mobile {
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .h4-text {
    &__link {
      &:hover {
        color: $dark_red;

        border-bottom: 1px solid $red_main;
      }
    }
  }
}
