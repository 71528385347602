.video {
  &__wrapper {
    text-align: center;
    margin: 0 auto;
    margin-top: 100px;
  }
  &__title {
  }
  &__watch {
    width: 400px;
    height: 400px;
    outline: none;
    &_account {
      margin-top: 60px;
    }
  }
}

@media (max-width: 767px) {
  .video {
    &__title {
      @include h1_mobile;
    }
    &__watch {
      width: 288px;
      height: 288px;
    }
  }
}
