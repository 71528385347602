.thanks {


  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__header-wrapper {
    padding: 100px 0 0;
  }

  &__block {
    padding: 100px 0 160px;
    text-align: center;
    width: 100%;
    min-height: 30px;
  }

  .thanks__appeal {
    margin-top: 100px;
    color: $white_main;
  }

  &__subheading {
    margin-top: 160px;
  }

  &__text {
    margin-top: 40px;
  }

  &__btn {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .thanks {

    &__header-wrapper {
      padding: 40px 0 0;
    }

    &__social {
      display: flex;
    }

    &__block {
      padding: 60px 0 160px;
    }

    &__heading {
      font-size: 61px !important;
    }

    &__subheading {
      margin-top: 80px;
      font-size: 24px !important;
    }

    &__text {
      margin-top: 32px;
      font-size: 16px !important;
    }

    &__btn {
      margin-top: 48px;
    }

  }
}

@media (max-width: 420px) {
  .thanks {

    &__social {
      display: none;
    }

    .block {
      padding: 40px 0 160px;
    }

    &__heading {
      font-size: 24px !important;
      line-height: 108% !important;
    }

    &__subheading {
      margin-top: 48px;
      font-size: 24px !important;
      line-height: 108% !important;
    }

    &__text {
      margin-top: 32px;
      font-size: 14px !important;
      line-height: 129% !important;
    }

  }
}

