@import './font';
@import './font_icon';
@import './mixins';
@import './colors';

@import 'blocks/buttons';
@import 'blocks/video';
@import 'blocks/modal';

@import 'blocks/b2b';
@import 'blocks/account';
@import 'blocks/thanksForPayment';

@import 'blocks/media/media';
@import 'blocks/media/media-b2b';
@import './typography';

// стили, которые убирают стрелочки у инпутов кода с type='number'
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: number-input;
}
a {
  text-decoration: none;
  color: inherit;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide {
  display: none;
}

.show {
  display: block;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  background-color: $background_color;
}
.container {
  margin: 0 100px;
}

input {
  border: none;
  &:focus {
    outline: none;
  }
}

@media (min-width: 1921px) {
  .container {
    width: 1600px;
    margin: 0 auto;
  }
}

@media (max-width: 1240px) {
  .container {
    margin: 0 100px;
  }
}

@media (max-width: 992px) {
  .container {
    margin: 0 40px;
  }
}

@media (max-width: 576px) {
  .container {
    margin: 0 16px;
  }
}
