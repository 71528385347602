.account {
  &__wrapper {
    padding: 80px 0px 32px 0px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__logo {
  }
  &__lk-icon {
    position: relative;

    &::before {
      content: '';
      background: $grey_secondary;
      border-radius: 50px;
      bottom: 0;
      color: $white_main;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
    &:hover {
      &::before {
        opacity: 0.1;
      }
    }
    &-left {
      margin-left: auto;
    }
  }
  &__tabs {
    display: flex;
    justify-content: center;
    & > ul {
      display: flex;
      justify-content: space-between;
      width: 462px;
      padding: 0px 8px;
      margin-top: 40px;
      list-style-type: none;
    }
  }
  &__tab {
    @include h4_text;
    font-size: 15px;
    color: $main_black;
    text-decoration: none;
    padding: 8px;
    cursor: pointer;
    &:hover {
      color: $red_main;
    }

    &_active {
      color: $red_main;
      border-bottom: 2px solid $red_main;
    }
  }
  &__dynamic {
    padding: 80px 0px 100px 0px;
    background-color: $main_black;
    width: 100%;
    min-height: 30px;
  }
  &__sign-out {
    position: absolute;
    bottom: -50px;
  }
  &__cards-history {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__card {
    width: 1220px;
    min-height: 136px;
    background-color: $white_main;
    margin: 0 auto;

    &_common {
      padding: 40px;
    }
    &_main-promocode {
      padding: 64px 0px;
      text-align: center;
    }
    &_black {
      background-color: $main_black;
      padding: 60px 0px 100px 0px;
      text-align: center;
    }
    &_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_green {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        left: 0px;
        height: 100%;
        background: #03ae00;
      }
    }
    &_mt-24 {
      margin-top: 24px;
    }
  }
  &__video {
    &-title {
      color: $white_main;
      margin-top: 100px;
    }
    &-descr {
      margin-top: 8px;
      color: $red_main;
    }
  }
  &__settings {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: start;
    &__item {
      margin-top: 40px;

      &:first-child {
        margin-top: 0px;
      }
    }
    &__data {
      display: flex;
      align-items: center;
      @include h4-text;
      color: $main_black;
      gap: 8px;

      span {
        display: block;
        padding-top: 5px;
      }
    }
    &__change {
      @include paragraph_massive;
      color: $red_main;
      text-transform: none;
      cursor: pointer;
      margin-top: 8px;
    }
  }
}

@media (max-width: 1220px) {
  .account {
    &__card {
      width: 688px;
    }
  }
}

@media (max-width: 768px) {
  .account {
    &__tabs {
      & > ul {
        margin-top: 80px;
      }
    }
    &__dynamic {
      padding: 60px 0px 80px 0px;
    }
    &__card {
      width: 560px;
      &_flex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &_black {
        padding: 4px 0px 20px 0px;
      }
    }
    &__cancel-monthly {
      margin-top: 16px;
    }
    &__settings {
      &__change {
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 576px) {
  .account {
    &__wrapper {
      padding: 40px 0px 32px 0px;
    }
    &__logo {
      width: 146px;
    }
    &__tabs {
      justify-content: center;

      & > ul {
        padding: 0;
        width: 364px;
        margin-top: 48px;
      }
    }
    &__dynamic {
      padding: 40px 0px 60px 0px;
    }
    &__sign-out {
      position: absolute;
      bottom: -47px;
      left: -47px;
    }
    &__tab {
      @include paragraph_massive;
      text-transform: none;
      display: flex;
      align-items: center;
      text-align: center;
    }
    &__card {
      width: 288px;
      text-align: center;
      &_common {
        padding: 40px 18px;
      }
      &_black {
        padding: 24px 0px 20px 0px;
      }
    }
    &_active-promocode {
      margin-top: 16px;
    }
    &__cancel-monthly {
      font-size: 14px;
      line-height: 18px;
    }
    &__settings {
      margin-top: 40px;
      align-items: center;
      text-align: center;
      &__item {
      }
      &__change {
        margin-top: 8px;
      }
    }
  }
}
