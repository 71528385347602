.b2b {
  &__forms-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px 80px 0px;

    margin: 0px auto;
    // min-height: 464px;
    text-align: center;
  }
  &__header {
    @include h2_desktop;
    color: $main_black;
    margin-top: 80px;
  }

  &__ogon {
    margin-top: 20px;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__dynamic {
    padding: 80px 0px 108px 0px;
    background-color: $main_black;
    width: 100%;
    min-height: 630px;
  }
  &__form-promocode {
    position: relative;
    width: 560px;
    margin-right: 20px;
    background-color: $white_main;
    padding: 64px 120px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__buttons {
    display: flex;
    width: 700px;

    justify-content: space-between;
    margin: 0 auto;
    margin-top: 40px;
  }
}

.layer {
  position: fixed;
  top: 0;
  display: block;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 1s all;
  &__spinner {
    position: absolute;
    height: 30px;
    width: 30px;
    border: 3px solid transparent;
    border-top-color: #cd2a34;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    bottom: 15px;
    border-radius: 50%;
    opacity: 0;
    animation-name: ani, spin;
    animation-duration: 1s, 1s;
    animation-iteration-count: infinite, infinite;

    &:before {
      border-top-color: #254e70;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      opacity: 0;
      animation-name: ani, spin;
      animation-duration: 1s, 1s;
      animation-iteration-count: infinite, infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
