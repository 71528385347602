@import '../../styles/colors';
@import '../../styles/mixins';

@media (max-width: 992px) {
  .social-media {
    gap: 24px;
    &_white {
      justify-content: center;
    }
    &__link {
      &:hover {
        .icon-account {
          &_white {
            &::before {
              content: url('../../assets/icons/account/account_light.svg');
            }
          }
          &_black {
            &::before {
              content: url('../../assets/icons/account/account.svg');
            }
          }
        }
      }
      &_white {
        span {
          &::before {
            color: $white_main;
          }
          &:hover {
            &::before {
              color: $white_main;
            }
          }
          &:active {
            &::before {
              color: $red_main;
            }
          }
        }
      }
      &_grey-secondary {
        span {
          &::before {
            color: $grey_secondary;
          }
          &:hover {
            &::before {
              color: $white_main;
            }
          }
          &:active {
            &::before {
              color: $red_main;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .social-media {
    &_footer {
      order: 1;
    }
  }
}
